import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["listForm"];

  connect() {
    console.log("ListForm has been connected");
  }

  toggle() {
    this.listFormTarget.classList.toggle("hidden");
  }
}
