import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["eventLightForm", "calendar"];

  toggleEventLightForm() {
    this.eventLightFormTarget.classList.toggle("hidden");
    if (!this.eventLightFormTarget.classList.contains("hidden")) {
      this.calendarTarget.classList.add("hidden");
    }
  }

  toggleCalendar() {
    this.calendarTarget.classList.toggle("hidden");
    if (!this.calendarTarget.classList.contains("hidden")) {
      this.eventLightFormTarget.classList.add("hidden");
    }
  }
}
